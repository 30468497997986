import { inject } from "mobx-react";
import { useEffect } from "react";

import { ResponsiveImage } from "../../components/ResponsiveImage.component";
import { generateImageAssetSizes } from "@inferno/renderer-shared-core";
import { getMediaServerUrl } from "@ihr-radioedit/inferno-core";
import { placeholderImage } from "@ihr-radioedit/inferno-core";
import { Container } from "../../ui";
import { PageBlockInterface } from "../Block.component";
import "./Asset.style.scss";
import { RESPONSIVE_IMAGE } from "../../ui/constants";
import type { Store } from "@inferno/renderer-shared-core";

interface AssetProps extends PageBlockInterface {
  store?: Store;
}

export const Asset = inject("store")(({ store, block }: AssetProps) => {
  if (!store) {
    return null;
  }

  useEffect(() => {
    store.storeBlock(block);
  });

  const { title = "", background_color, asset } = block.value;
  const imageUrl = getMediaServerUrl(store.env.IMAGE_HOST, asset.bucket, asset.id);
  const { srcset, sizes } = generateImageAssetSizes(imageUrl);
  const placeholderSrc = `${placeholderImage}?ops=resize(900,506),quality(10)`;
  const imageStyle = background_color ? { backgroundColor: background_color } : {};

  return (
    <Container>
      <figure className="component-image-asset">
        <section className="image-container" style={imageStyle}>
          <ResponsiveImage
            src={placeholderSrc}
            placeholder={placeholderSrc}
            alt={title}
            srcset={srcset}
            sizes={sizes}
            initialWidth={RESPONSIVE_IMAGE.responsiveImageInitialWidth}
            initialHeight={RESPONSIVE_IMAGE.responsiveImageInitialHeight}
          />
        </section>
      </figure>
    </Container>
  );
});

export default Asset;
